import React from 'react';
import { Container, H3, Spacer } from '@thisisdevelopment/akzonobel-core';

function Search() {
  return (
    <Container>
      <Spacer mt="30px" mb="40px">
        <H3>Search results</H3>
      </Spacer>
    </Container>
  );
}

export default Search;
