import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useCmsItem } from '@thisisdevelopment/akzonobel-core';
import { InspirationType } from 'pages/Inspiration';
import {
  Page,
  Wysiwyg,
  Loading,
  CmsRelatedArticles,
} from '@thisisdevelopment/heritage-core-library';
import { shuffleArray } from 'pages/ColorDetails/helper';
import { MainImage, ArticleContainer } from './styled';

const InspirationArticle = () => {
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const [category, setCategory] = useState('');
  const [article, fetchArticle, isLoading, error] = useCmsItem<InspirationType>('inspiration');
  const [allArticles, fetchAllArticles, isLoadingAllArticles] =
    useCmsItem<InspirationType[]>('inspiration');

  useEffect(() => {
    id && fetchArticle(+id);
  }, [fetchArticle, id]);

  useEffect(() => {
    fetchAllArticles();
  }, [fetchAllArticles]);

  useEffect(() => {
    if (article) {
      setCategory(article.category[0]);
    }
  }, [article]);

  useEffect(() => {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      history.push('/notfound');
    }
  }, [error, history]);

  const articles = useMemo(() => {
    const articlesFiltered =
      allArticles && shuffleArray(allArticles.filter((item) => item.category.includes(category)));
    return (
      articlesFiltered && articlesFiltered.filter((item) => item.id !== article.id).slice(0, 3)
    );
  }, [allArticles, category, article]);

  if (isLoading) {
    return <Loading />;
  }

  if (article) {
    return (
      <Page
        title={article.title}
        prefix="Inspiration Article"
        prefixColor={theme.colors.brandGold}
        color={theme.colors.brandBlueDark}
        bgColor={theme.colors.white}
      >
        <MainImage id={article.image} />
        <ArticleContainer>
          <Wysiwyg content={article.body} />
        </ArticleContainer>
        {!isLoadingAllArticles && (
          <CmsRelatedArticles title="Related articles" items={articles} mb="0px" />
        )}
        {}
      </Page>
    );
  }

  return null;
};

export default InspirationArticle;
