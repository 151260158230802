import theme from 'theme';
import styled from 'styled-components';

export const Articles = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin: 40px 80px;

  @media ${theme.mediaQuery.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 20px;
  }

  @media ${theme.mediaQuery.mobile} {
    grid-template-columns: auto;
    margin: 20px 0;
  }
`;