import React, { useState } from 'react';
import {
  Wrapper,
  H4,
  H5,
  Anchor,
  Paragraph,
  Button,
  validation,
  useForm,
  InputSelect,
  bffService,
} from '@thisisdevelopment/akzonobel-core';
import { ContactUsWrapper, SendAMessage, CallUs, MessageSent } from './styled';
import form from './form.json';
import { Page, InputField, ContainerInner } from '@thisisdevelopment/heritage-core-library';

function ContactUs() {
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);
  const initialValues = {
    name: '',
    email: '',
    reason: '',
    query: '',
    orderNumber: '',
    productRef: '',
  };
  const formValidation = {
    name: [validation.required],
    email: [validation.required, validation.email],
    reason: [validation.required],
    query: [validation.required],
  };
  const { bindInput, handleSubmit } = useForm(initialValues, formValidation);

  const reasonOptions = form.formData
    .find(({ id }) => id === 'reason')
    ?.options?.map(({ optionDesc, id }) => ({ value: id, label: optionDesc }));

  const sendContact = async (values: any) => {
    const payload = {
      ...form,
      formData: form.formData.map((field) => ({ ...field, values: [values[field.id]] })),
    };
    await bffService.sendContact(payload);
    setIsMessageSent(true);
  };

  return (
    <Page title="Contact Us">
      <ContainerInner>
        <ContactUsWrapper>
          {!isMessageSent && (
            <SendAMessage>
              <H4 mb="20px">Send us a message</H4>
              <InputField label="Name" mb="20px" {...bindInput('name')} autoFocus />
              <InputField label="E-mail" mb="20px" type="email" {...bindInput('email')} />
              <InputField label="Phone number" mb="20px" {...bindInput('telephone')} />
              {reasonOptions && (
                <InputSelect
                  label="Reason"
                  mb="20px"
                  options={[...reasonOptions]}
                  placeholder="Select the reason"
                  {...bindInput('reason')}
                />
              )}
              <InputField
                label="Order Number - if applicable"
                mb="20px"
                {...bindInput('orderNumber')}
              />
              <InputField
                label="Product Name / Barcode / Batch Number - if applicable"
                mb="20px"
                {...bindInput('productRef')}
              />
              <InputField
                textArea
                label="Your message"
                height="130px"
                mb="40px"
                {...bindInput('query')}
              />
              <Button color="secondary" width="200px" onClick={() => handleSubmit(sendContact)}>
                Send
              </Button>
            </SendAMessage>
          )}
          {isMessageSent && (
            <MessageSent>
              <H4 mb="20px">Thank you!</H4>
              Your message was submitted successfully.
            </MessageSent>
          )}
          <CallUs>
            <H5>Call us</H5>
            <Paragraph>
              For Customer Service and Product Advice on{' '}
              <Anchor href="tel:021 4220222">021 4220222</Anchor>
            </Paragraph>
{/*            <Paragraph fontWeight="extrabold" mb="0">
              Open between
            </Paragraph>
            <Paragraph mt="5px">
              8:30am - 5:00pm Monday to Friday
              <br />
              9:00am - 12:30pm Saturday
            </Paragraph>*/}
            <H5 mb="16px" mt="32px">
              E-mail us
            </H5>
            <Paragraph>
              <Anchor href="mailto:info@dulux.ie">info@dulux.ie</Anchor>
            </Paragraph>
            <H5 mb="16px" mt="32px">
              Write us
            </H5>
            <Wrapper as="address">
              Dulux Heritage
              <br /> Commons Road
              <br /> Cork
              <br /> Ireland
            </Wrapper>
          </CallUs>
        </ContactUsWrapper>
      </ContainerInner>
    </Page>
  );
}

export default ContactUs;
