export default {
  default: {
    title: 'Heritage by Dulux - Luxury, Premium & High Quality Paint',
    description:
      "Discover Heritage by Dulux, a designer collection of premium paint. We've taken 85 years of experience to design a range of luxury colours to suit your home.",
    keywords: 'heritage paint, dulux heritage paint, premium paint, luxury paints, designer paint',
  },
  colors: {
    title: 'Luxury Paint Colours -  Colour Chart & Palettes | Heritage',
    description:
      'Explore our designer collection of luxury paint colours and discover rich, versatile tones perfect for every room. Browse our colour charts and palettes here.',
    keywords:
      'paint colours, paint colour chart, colour palette, heritage paint colours, luxury paint colours',
  },
  colorDetails: {
    title: '$colorName - Colours | Heritage',
    description: "Discover Heritage's luxury $colorName paint.",
    keywords:
      'paint colours, paint colour chart, colour palette, heritage paint colours, luxury paint colours',
  },
  stores: {
    title: 'Heritage Store Locator - Paint Store Near Me | Heritage',
    description:
      'Find your nearest store and stockist of Heritage paint. Simply search your address or area and our store locator will find your nearest stockist. Learn more.',
    keywords: 'store locator, paint store near me, paint store, find a store',
  },
  products: {
    title: 'Paint Finishes - Matt, Eggshell & Primer Paint | Heritage',
    description:
      'Choose from our range of versatile paint finishes from classic, long lasting matt paint to quick quick-drying primer undercoats and eggshell paint. Shop now.',
    keywords: 'paint finishes, matt paint, eggshell paint, undercoat paint, primer paint',
  },
  inspiration: {
    title: 'Inspiration - Painting & Decorating Ideas | Heritage',
    description:
      "Get inspired with Heritage's curated collection of painting ideas, decorating guides and how tos. Browse through room inspiration and colour ideas here.",
    keywords: 'painting ideas, decorating ideas, room ideas, room inspiration, room colours',
  },
  ourProducts: {
    title: 'High Quality Interior Paint - Collection & Colours | Heritage',
    description:
      "Discover Heritage's beautiful collection of premium paint available in a range of finishes and featuring a large variety of rich, luxury colours. Explore here.",
    keywords: 'paint, interior paint, wall paint, interior wall paint, indoor paint',
  },
};
