import React from 'react';
import Routes from './routes';
import { Topbar, Footer, ContainerFull } from '@thisisdevelopment/heritage-core-library';
import styled, { ThemeProvider } from 'styled-components';
import {
  NotificationsContextProvider,
  EcomContextProvider,
} from '@thisisdevelopment/akzonobel-core';
import theme from 'theme';
import GlobalStyle from 'theme/GlobalStyle';

const AppStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <NotificationsContextProvider>
        <EcomContextProvider>
          <Topbar />
          <ContainerFull>
            <AppStyled>
              <Routes />
              <Footer />
            </AppStyled>
          </ContainerFull>
        </EcomContextProvider>
      </NotificationsContextProvider>
    </ThemeProvider>
  );
}

export default App;
