import { bffService, ecomService, config, DistanceUnit } from '@thisisdevelopment/akzonobel-core';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KTGQRMH',
};

export const init = () => {
  const ecomURL = process.env.REACT_APP_ECOM_URL;

  config.googleApiKey = `${process.env.REACT_APP_GOOGLE_API_KEY}`;
  config.bugsnagApiKey = `${process.env.REACT_APP_BUGSNAG_API_KEY}`;
  config.sprinklrEnabledHome = false;
  config.sprinklrEnabledColor = false;
  config.showCookieSettings = true;
  config.sprinklrClientId = '';
  config.sprinklrEmbedId = '';
  config.domainCode = `${process.env.REACT_APP_DOMAIN_CODE}`;
  config.channel = `${process.env.REACT_APP_CHANNEL}`;
  config.language = `${process.env.REACT_APP_LANGUAGE}`;
  config.distanceUnit = DistanceUnit.Imperial;
  config.cartUrl = `${ecomURL}/cart`;
  config.ecomEnabled = false;
  config.socials = {
    instagram: 'https://www.instagram.com/duluxheritageireland',
  };
  config.relatedSites = [
    {
      url: 'https://www.dulux.ie',
      title: 'Dulux',
    },
    {
      url: 'https://www.cuprinol.ie/index.jsp',
      title: 'Cuprinol',
    },
    {
      url: 'http://www.hammerite.ie',
      title: 'Hammerite',
    },
    {
      url: 'https://www.weathershield.ie',
      title: 'Weathershield',
    },
    {
      url: 'https://www.duluxtradepaintexpert.ie',
      title: 'Dulux Trade Paint Expert',
    },
  ];
  config.menuItems = [
    { label: 'The Heritage Story', to: '/en/the-heritage-story' },
    {
      label: 'Find your paint',
      items: [
        { label: 'Our colours', to: '/en/colours' },
        { label: 'Our products', to: '/en/products' },
      ],
    },
    {
      label: 'Inspiration and Advice',
      items: [
        { label: 'Ideas', to: '/en/inspiration/category/ideas' },
        { label: 'Practical help', to: '/en/inspiration/category/practical-help' },
      ],
    },
    { label: 'For Professionals', to: '/en/for-professionals' },
    { label: 'Stockists', to: '/en/stores' },
  ];

  bffService.initAxiosConfig(`${process.env.REACT_APP_BFF_URL}`);
  ecomService.initAxiosInstance(`${ecomURL}/api`);
  TagManager.initialize(tagManagerArgs);
};
