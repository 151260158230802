import React, { useEffect } from 'react';
import { useCmsItem } from '@thisisdevelopment/akzonobel-core';
import {
  Page,
  CmsSections,
  CmsSectionType,
  CmsVideo,
  FooterBlocks,
  Loading,
} from '@thisisdevelopment/heritage-core-library';

type TheHeritageStoryType = {
  title: string;
  headline: string;
  video_cover: number;
  video: number;
  sections: CmsSectionType[];
};

function TheHeritageStory() {
  const [page, fetchPage, isLoading] = useCmsItem<TheHeritageStoryType>('the_heritage_story', {
    single: true,
  });

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  if (isLoading) {
    return <Loading />;
  }

  if (page) {
    return (
      <Page title={page.title} headline={page.headline}>
        <CmsSections sections={page.sections} />
        <CmsVideo coverId={page.video_cover} videoId={page.video} mt="50px" />
        <FooterBlocks page="the-heritage-story" my="40px" />
      </Page>
    );
  }

  return null;
}

export default TheHeritageStory;
