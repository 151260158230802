/* eslint-disable react/no-unescaped-entities */
import { Page } from '@thisisdevelopment/heritage-core-library';
import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import styled from 'styled-components';

const LanguageDropDown = styled.div`
  select {
    padding: 8px 10px;
    border-radius: 4px;
  }
`;
const Notice = styled.div`
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    padding-bottom: 8px;
  }
  a {
    color: #c0ad86;
    font-family: 'Source Sans Pro', sans-serif;
    text-underline-position: under;
    font-weight: 600;
    font-size: 1.1em;
  }
`;

function CookiePolicy() {
  const [otNoticeApiLoaded, setOtNoticeApiLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (otNoticeApiLoaded) {
        clearInterval(interval);
      }
      if (typeof window.OneTrust.NoticeApi.Initialized !== 'undefined') {
        setOtNoticeApiLoaded(true);
        window.OneTrust.NoticeApi.Initialized.then(function () {
          window.OneTrust?.NoticeApi.LoadNotices(
            [
              'https://privacyportalde-cdn.onetrust.com/24527c35-1de1-4aaa-833d-de50b42446bd/privacy-notices/8f61895d-5abb-45b4-a72d-c2c42df7974e.json',
            ],
            true,
            'en-us'
          );
        });
      }
    }, 200);
    return () => clearInterval(interval);
  }, [otNoticeApiLoaded]);

  return (
    <HelmetProvider>
      <Helmet>
        <script
          src="https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          id="otprivacy-notice-script"
        >
          settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"
        </script>
      </Helmet>
      <Page title="Cookie Policy">
        <LanguageDropDown className="otnotice-language-dropdown-container">
          <select id="otnotice-language-dropdown" aria-label="language selector"></select>
        </LanguageDropDown>
        <Notice id="otnotice-8f61895d-5abb-45b4-a72d-c2c42df7974e" className="otnotice" />
      </Page>
    </HelmetProvider>
  );
}

export default CookiePolicy;
