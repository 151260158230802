import styled from 'styled-components';
import { mediaQuery } from 'theme';
import { ContainerInner } from 'elements';

export const ProductDescriptions = styled(ContainerInner)`
  display: grid;
  grid-template: 1fr / repeat(3, 1fr);
  gap: 40px;
  padding: 30px 0 70px 0;

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }

  p {
    font-size: 1.3rem;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 5px 0 0 0;
    letter-spacing: 0.05em;
  }

  @media ${mediaQuery.tablet} {
    grid-template: repeat(3, auto) / 1fr;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.brandBlue};
    margin: 30px 0;
    padding: 30px 20px 70px 20px;

    h2 {
      color: ${({ theme }) => theme.colors.brandGold};
    }

    p {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DownloadDescriptions = styled(ContainerInner)`
  padding-bottom: 30px;

  h2 {
    font-size: 1.4rem;
    font-weight: 600;
  }

  p {
    font-size: 1.3rem;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0 0 0 0;
    letter-spacing: 0.05em;
  }

  .downloadWrapper {
    width: 100%;
    height: 2em;
    display: flex;
    border-top: 0.1rem solid #e1e1e1;
  }

  .downloadWrapper:last-child {
    border-bottom: 0.1rem solid #e1e1e1;
  }

  .pdfLogo {
    height: 80%;
    padding-left: 10px;
    padding-top: 5px;
    padding-right: 10px;
  }

  .downloadLogo {
    height: 80%;
    display: block;
    margin-left: auto;
    margin-right: 10px;
    padding-top: 5px;
    cursor: pointer;
  }

  .downloadTitle {
    padding-top: 10px;
  }

  .downloadType {
    padding-top: 10px;
    font-size: 1rem;
    text-align: right;
    color: ${({ theme }) => theme.colors.brandDarkGreen};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.brandGold};
    }
  }
`;
