import React, { useEffect, FC } from 'react';
import { useCmsItem, Wrapper } from '@thisisdevelopment/akzonobel-core';
import {
  Page,
  CmsSections,
  CmsSectionType,
  CmsVideo,
  Loading,
} from '@thisisdevelopment/heritage-core-library';
import { Banner } from './styled';

export type OurProductsType = {
  title: string;
  banner: number;
  headline: string;
  video_cover: number;
  video: number;
  sections: CmsSectionType[];
  product_list_background: number;
};

const OurProducts: FC = () => {
  const [page, fetchPage, isLoading] = useCmsItem<OurProductsType>('our_products', {
    single: true,
  });

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  if (isLoading) {
    return <Loading />;
  }

  if (page) {
    return (
      <Page title={page.title} headline={page.headline}>
        <Banner id={page.banner} height="400px" width="100%" objectFit="cover" />
        <Wrapper mb="30px">
          <CmsSections sections={page.sections} />
        </Wrapper>
        <CmsVideo coverId={page.video_cover} videoId={page.video} mb="50px" />
      </Page>
    );
  }

  return null;
};

export default OurProducts;
