import React, { useEffect, useMemo, FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  useProduct,
  useQuery,
  useColor,
  useCmsItem,
  Wrapper,
  H2,
  Paragraph,
  Svg,
} from '@thisisdevelopment/akzonobel-core';
import { OurProductsType } from 'pages/OurProducts';
import { Page, ProductColor, Loading, CmsVideo } from '@thisisdevelopment/heritage-core-library';
import { ProductDescriptions, DownloadDescriptions } from './styled';
import { hydrateColor } from '../ColorDetails/helper';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { hasColors, isAccessory, isColorCard } from 'util/product';

const ProductDetails: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const [page, fetchPage, isLoadingPage] = useCmsItem<OurProductsType>('our_products', {
    single: true,
  });
  const { product, fetchProduct, error, isLoading } = useProduct();
  const { color, fetchColor } = useColor();
  const { friendlyName } = useParams<{ friendlyName: string }>();
  const query = useQuery();
  const cccid = query.get('cccid') ?? (product && hasColors(product) ? '1780085' : null);
  const isMobile = useMediaQuery(theme.mediaQuery.mobile);
  const pickAColour = query.get('pickAColour');

  useEffect(() => {
    fetchProduct({ friendlyName });
  }, [fetchProduct, friendlyName]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      history.push('/notfound');
    }
  }, [error, history]);

  useEffect(() => {
    if (product?.colorCollectionColorIds.length === 1) {
      history.replace(`?cccid=${product?.colorCollectionColorIds[0]}`);
    }
  }, [product, history]);

  useEffect(() => {
    if (product && cccid) {
      if (product.colorCollectionColorIds.includes(Number(cccid))) {
        fetchColor(cccid);
      } else {
        history.push('/notfound');
      }
    }
  }, [cccid, product, fetchColor, history]);

  const colorHydrated = useMemo(() => color && hydrateColor(color), [color]);

  if (isLoading || isLoadingPage) {
    return <Loading />;
  }

  if (page && product) {
    return (
      <Page title={page.title} showHeader={!isMobile}>
        <ProductColor
          mode="Product"
          product={product}
          color={color}
          backgroundImage={
            hasColors(product)
              ? colorHydrated?.inspirationalImages?.[0].url
              : page.product_list_background
          }
          openColors={pickAColour === 'true'}
        />
        {!isColorCard(product) && !isAccessory(product) && (
          <Wrapper>
            <Wrapper>
              <ProductDescriptions>
                <Wrapper>
                  <H2>Application description</H2>
                  <Paragraph>{product.applicationDescription}</Paragraph>
                </Wrapper>
                <Wrapper>
                  <H2>Surface preparation</H2>
                  <Paragraph>{product.surfaceDescription}</Paragraph>
                </Wrapper>
                <Wrapper>
                  <H2>Storage</H2>
                  <Paragraph>{product.storage}</Paragraph>
                </Wrapper>
              </ProductDescriptions>
            </Wrapper>
            {product.documents.length > 0 && (
              <Wrapper>
                <DownloadDescriptions>
                  <Wrapper>
                    <H2>Health & Safety</H2>
                    {product.hse && <Paragraph className="downloadTitle">{product.hse}</Paragraph>}
                    <Paragraph
                      onClick={() =>
                        window.open('https://get.adobe.com/reader/otherversions/', '_blank')
                      }
                      className="downloadType"
                    >
                      Download Adobe Reader
                    </Paragraph>
                  </Wrapper>
                  {product.documents.map((item, index) => (
                    <Wrapper key={index} className="downloadWrapper">
                      <Svg.Pdf className="pdfLogo"></Svg.Pdf>
                      {item.description && (
                        <Paragraph className="fileName">{item.description}</Paragraph>
                      )}
                      {!item.description && (
                        <Paragraph className="fileName">{item.title}</Paragraph>
                      )}
                      <Svg.Download
                        onClick={() => window.open(item.url, '_blank')}
                        className="downloadLogo"
                      ></Svg.Download>
                    </Wrapper>
                  ))}
                </DownloadDescriptions>
              </Wrapper>
            )}
          </Wrapper>
        )}
        <CmsVideo coverId={page.video_cover} videoId={page.video} mb="50px" />
      </Page>
    );
  }

  return null;
};

export default ProductDetails;
