import React, { useEffect, useMemo, useState, FC, Fragment } from 'react';
import { useTheme } from 'styled-components';
import groupBy from 'lodash/groupBy';
import {
  useCmsItem,
  Paragraph,
  Container,
  H1,
  useColors,
  Wrapper,
  Text,
  Image,
  FlexRow,
} from '@thisisdevelopment/akzonobel-core';
import {
  Page,
  ColorCard,
  Loading,
  CmsVideo,
  ColorWall,
  CmsSectionType,
} from '@thisisdevelopment/heritage-core-library';
import {
  GroupButton,
  ColorsWrapper,
  ColorsHeader,
  ColorsContent,
  GroupImageWrapper,
  GroupName,
  FlexRowStyled,
} from './styled';
import groups from './groups.json';

type OurColorsType = {
  title: string;
  headline: string;
  video_cover: number;
  video: number;
  sections: CmsSectionType[];
};

const Colors: FC = () => {
  const theme = useTheme();
  const [selectedGroup, setSelectedgroup] = useState<string>(groups[0].name);
  const [page, fetchPage, isLoadingPage] = useCmsItem<OurColorsType>('our_colours', {
    single: true,
  });
  const { colors, fetchColors, isLoading } = useColors();

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  useEffect(() => {
    fetchColors('', { flip90AntiClockwise: true });
  }, [fetchColors]);

  const colorsGrouped = useMemo(() => {
    if (!colors) {
      return [];
    }

    const group = groups.find(({ name }) => name === selectedGroup);
    const colorGroups = groupBy(colors, group?.path);

    return Object.keys(colorGroups).map((groupName) => {
      const groupItem = group?.items.find(({ name }) => name === groupName);

      return {
        name: groupName,
        colors: colorGroups[groupName],
        description: groupItem?.description,
        headerImage: groupItem?.headerImage,
      };
    });
  }, [colors, selectedGroup]);

  if (isLoading || isLoadingPage) {
    return <Loading />;
  }

  if (page && colors) {
    return (
      <Page title={page.title} headline={page.headline}>
        <FlexRowStyled padding="20px" justifyContent="center" alignItems="center">
          <Text color={theme.colors.brandGray400}>Organize by: </Text>
          <FlexRow>
            {groups.map((group) => (
              <GroupButton
                key={`GroupButton_${group.name}`}
                onClick={() => setSelectedgroup(group.name)}
                isActive={group.name === selectedGroup}
              >
                {group.name}
              </GroupButton>
            ))}
          </FlexRow>
        </FlexRowStyled>

        {selectedGroup !== 'colour wall' && (
          <Wrapper>
            {colorsGrouped.map(({ name, colors, description, headerImage }) => (
              <Fragment key={`ColorGroup_${name}`}>
                {headerImage && (
                  <GroupImageWrapper>
                    <Image src={headerImage} />
                    <GroupName>{name}</GroupName>
                  </GroupImageWrapper>
                )}
                <ColorsWrapper as="section">
                  <ColorsHeader>
                    <H1>{name}</H1>
                    <Paragraph>{description}</Paragraph>
                  </ColorsHeader>
                  <ColorsContent>
                    {colors.map((color) => (
                      <ColorCard
                        mode="Color"
                        key={`Color_${color.colorCollectionColorId}`}
                        color={color}
                      />
                    ))}
                  </ColorsContent>
                </ColorsWrapper>
              </Fragment>
            ))}
          </Wrapper>
        )}
        {selectedGroup === 'colour wall' && (
          <Wrapper pt="15px" pb="40px">
            <Container>
              <Wrapper bgColor={theme.colors.white} padding="0 10px 10px 10px">
                <ColorWall colors={colors} />
              </Wrapper>
            </Container>
          </Wrapper>
        )}
        <CmsVideo coverId={page.video_cover} videoId={page.video} mb="50px" />
      </Page>
    );
  }

  return null;
};

export default Colors;
