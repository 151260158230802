import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { updateMeta } from '@thisisdevelopment/akzonobel-core';
import metas from './meta';
import Colors from 'pages/Colors';
import ColorDetailsPage from 'pages/ColorDetails';
import Stores from 'pages/Stores';
import Products from 'pages/Products';
import HomePage from 'pages/Home';
import Inspiration from 'pages/Inspiration';
import ProductDetails from 'pages/ProductDetails';
import OurProducts from 'pages/OurProducts';
import Search from 'pages/Search';
import InspirationArticle from 'pages/InspirationArticle';
import NotFound from 'pages/NotFound';
import TermsAndConditions from 'pages/TermsAndConditions';
import CookiePolicy from 'pages/CookiePolicy';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import ContactUs from 'pages/ContactUs';
import TheHeritageStory from 'pages/TheHeritageStory';
import ForProfessionals from 'pages/ForProfessionals';

const routes = [
  {
    path: '/en/',
    Component: HomePage,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/colours',
    Component: Colors,
    meta: metas.colors,
    scrollToTop: true,
  },
  {
    path: '/en/colours/:cccid/:uriFriendlyName',
    Component: ColorDetailsPage,
    scrollToTop: false,
  },
  {
    path: '/en/colours/:cccid',
    Component: ColorDetailsPage,
    scrollToTop: false,
  },
  {
    path: '/en/stores',
    Component: Stores,
    meta: metas.stores,
    scrollToTop: true,
  },
  {
    path: '/en/products/all',
    Component: Products,
    meta: metas.products,
    scrollToTop: true,
  },
  {
    path: '/en/products/details/:friendlyName',
    Component: ProductDetails,
    meta: metas.products,
    scrollToTop: false,
  },
  {
    path: '/en/inspiration/category/:category',
    Component: Inspiration,
    meta: metas.inspiration,
    scrollToTop: true,
  },
  {
    path: '/en/inspiration/article/:id/:friendlyUrlTitle',
    Component: InspirationArticle,
    meta: metas.inspiration,
    scrollToTop: true,
  },
  {
    path: '/en/for-professionals',
    Component: ForProfessionals,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/the-heritage-story',
    Component: TheHeritageStory,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/products',
    Component: OurProducts,
    meta: metas.ourProducts,
    scrollToTop: true,
  },
  {
    path: '/en/search',
    Component: Search,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/terms-and-conditions',
    Component: TermsAndConditions,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/cookie-policy',
    Component: CookiePolicy,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/privacy-policy',
    Component: PrivacyPolicy,
    meta: metas.default,
    scrollToTop: true,
  },
  {
    path: '/en/contact-us',
    Component: ContactUs,
    meta: metas.default,
    scrollToTop: true,
  },
];

function Routes() {
  return (
    <Switch>
      {routes.map(({ path, meta, scrollToTop, Component }) => (
        <Route
          key={`Route_${path}`}
          path={path}
          exact
          render={() => {
            meta && updateMeta(meta);
            scrollToTop && window.scrollTo({ top: 0 });
            return <Component />;
          }}
        />
      ))}
      <Redirect path="/" to="/en/" />
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

export default Routes;
