import styled from 'styled-components';
import { CmsVideo } from '@thisisdevelopment/heritage-core-library';
import theme from 'theme';

export const Photos = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 50px;

  img {
    object-fit: cover;
    width: 100%;
    max-height: 650px;

    &:first-child,
    &:last-child {
      grid-column: 1 / 3;
    }
  }

  @media ${theme.mediaQuery.tablet} {
    grid-template-columns: 1fr;

    img {
      &:first-child,
      &:last-child {
        grid-column: unset;
      }
    }
  }
`;

export const ColorVideo = styled(CmsVideo)`
  background-color: ${({ theme }) => theme.colors.white};
  padding-top: 40px;
`;
