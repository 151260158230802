import React from 'react';
import { FindStores } from '@thisisdevelopment/akzonobel-core';
import { Page, ContainerInner } from '@thisisdevelopment/heritage-core-library';

function Stores() {
  return (
    <Page title="Find a Store">
      <ContainerInner mb="60px">
        <FindStores
          title="Find a store that stocks Heritage"
          headline="In the coming weeks we will be updating this page with more stockists as we continue our product rollout."
          initialZoom={6}
          initialCenter={{ lat: 54.5437, lng: -4.3601 }}
          distanceRadius="50"
        />
      </ContainerInner>
    </Page>
  );
}

export default Stores;
