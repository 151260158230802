import React, { useEffect } from 'react';
import { useCmsItem } from '@thisisdevelopment/akzonobel-core';
import { Page, Wysiwyg, Loading, ContainerInner } from '@thisisdevelopment/heritage-core-library';

type TermsAndConditionsType = {
  text: string;
};

function TermsAndConditions() {
  const [page, fetchPage, isLoading] = useCmsItem<TermsAndConditionsType>('terms_and_conditions', {
    single: true,
  });

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page title="Terms and Conditions">
      <ContainerInner my="80px">
        <Wysiwyg content={page?.text} />
      </ContainerInner>
    </Page>
  );
}

export default TermsAndConditions;
