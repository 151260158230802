import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useCmsItem, Wrapper, config } from '@thisisdevelopment/akzonobel-core';
import {
  CmsSections,
  CmsSectionType,
  CmsBanner,
  FooterBlocks,
  Loading,
  CmsCallToActions,
  CmsCallToActionType,
  Sprinklr,
} from '@thisisdevelopment/heritage-core-library';

type HomePageType = {
  banner: number;
  banner_title: string;
  banner_subtitle: string;
  banner_headline: string;
  button_link: string;
  button_label: string;
  sections: CmsSectionType[];
  call_to_actions_title: string;
  call_to_actions: CmsCallToActionType[];
};

function HomePage() {
  const theme = useTheme();
  const [page, fetchPage, isLoading] = useCmsItem<HomePageType>('homepage', { single: true });

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  return (
    <Wrapper as="main" bgColor={theme.colors.white}>
      {!isLoading && page && (
        <>
          <CmsBanner
            imageId={page.banner}
            title={page.banner_title}
            subtitle={page.banner_subtitle}
            headline={page.banner_headline}
            buttonLink={page.button_link}
            buttonLabel={page.button_label}
          />
          <CmsSections sections={page.sections} />
          <CmsCallToActions
            title={page.call_to_actions_title}
            items={page.call_to_actions}
            mb="40px"
          />
          <FooterBlocks page="homepage" mb="40px" />
          {config.sprinklrEnabledHome && <Sprinklr />}
        </>
      )}
      {isLoading && <Loading />}
    </Wrapper>
  );
}

export default HomePage;
