import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCmsItem, FlexRow } from '@thisisdevelopment/akzonobel-core';
import { Page, Loading, InspirationCard } from '@thisisdevelopment/heritage-core-library';
import { GroupButton } from '../Colors/styled';
import theme from 'theme';
import groups from './groups.json';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Articles } from './styled';

type InspirationPageType = {
  slug: string;
  title: string;
  headline: string;
};

export type InspirationType = {
  id: number;
  title: string;
  headline: string;
  summary: string;
  body: string;
  image: number;
  thumbnail: number;
  category: string[];
  sub_category: string;
  highlight: boolean;
  friendly_url_title: string;
};

function Inspiration() {
  const [allPages, fetchAllPages, isLoadingAllPages] =
    useCmsItem<InspirationPageType[]>('inspiration_pages');
  const [allArticles, fetchAllArticles, isLoadingAllArticles] =
    useCmsItem<InspirationType[]>('inspiration');
  const { category } = useParams<{ category: string }>();
  const [selectedGroup, setSelectedgroup] = useState<string>(groups[0].subcategory);
  const isMobile = useMediaQuery(theme.mediaQuery.mobile);

  useEffect(() => {
    fetchAllArticles();
    fetchAllPages();
  }, [fetchAllArticles, fetchAllPages]);

  const page = useMemo(() => {
    return allPages && allPages.find(({ slug }) => slug === category);
  }, [allPages, category]);

  const articles = useMemo(() => {
    return allArticles && allArticles.filter((item) => item.category.includes(category));
  }, [allArticles, category]);

  const articlesGrouped = useMemo(() => {
    if (!articles) {
      return [];
    }

    if (isMobile) {
      return articles;
    }

    return articles.filter((article) => {
      return article.sub_category === selectedGroup;
    });
  }, [articles, selectedGroup, isMobile]);

  const highlightedArticle = useMemo(() => {
    return articlesGrouped && articlesGrouped.find(({ highlight }) => highlight);
  }, [articlesGrouped]);

  const otherArticles = useMemo(() => {
    return articlesGrouped && articlesGrouped.filter(({ highlight }) => !highlight);
  }, [articlesGrouped]);

  if (isLoadingAllPages || isLoadingAllArticles) {
    return <Loading />;
  }

  if (page) {
    return (
      <Page title={page.title} headline={page.headline}>
        {!isMobile && (
          <FlexRow padding="20px" justifyContent="center" alignItems="center">
            {groups.map((group) => (
              <GroupButton
                key={`GroupButton_${group.subcategory}`}
                onClick={() => setSelectedgroup(group.subcategory)}
                isActive={group.subcategory === selectedGroup}
              >
                {group.subcategory}
              </GroupButton>
            ))}
          </FlexRow>
        )}
        {highlightedArticle && <InspirationCard highlight article={highlightedArticle} />}
        <Articles>
          {otherArticles.map((article) => (
            <InspirationCard key={`InspirationCard_${article.id}`} article={article} />
          ))}
        </Articles>
      </Page>
    );
  }

  return null;
}

export default Inspiration;
