import React, { useEffect } from 'react';
import { useCmsItem } from '@thisisdevelopment/akzonobel-core';
import {
  Page,
  Loading,
  CmsSections,
  CmsSectionType,
  CmsVideo,
  FooterBlocks,
} from '@thisisdevelopment/heritage-core-library';

type ForProfessionalsType = {
  title: string;
  headline: string;
  video_cover: number;
  video: number;
  sections: CmsSectionType[];
};

function ForProfessionals() {
  const [page, fetchPage, isLoading] = useCmsItem<ForProfessionalsType>('for_professionals', {
    single: true,
  });

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  if (isLoading) {
    return <Loading />;
  }

  if (page) {
    return (
      <Page title={page.title} headline={page.headline}>
        <CmsSections sections={page.sections} />
        <CmsVideo coverId={page.video_cover} videoId={page.video} mt="50px" />
        <FooterBlocks page="for-professionals" my="40px" />
      </Page>
    );
  }

  return null;
}

export default ForProfessionals;
