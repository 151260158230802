import React, { useEffect, useMemo, FC } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  useColor,
  Image,
  getColorNameByBgColorHex,
  updateMeta,
  useProducts,
  useQuery,
  useProduct,
  config,
} from '@thisisdevelopment/akzonobel-core';
import {
  ColorCombinations,
  Page,
  ProductColor,
  Sprinklr,
} from '@thisisdevelopment/heritage-core-library';
import { Photos, ColorVideo } from './styled';
import { hydrateColor } from './helper';
import metas from 'routes/meta';

const ColorDetailsPage: FC = () => {
  const { cccid } = useParams<{ cccid: string }>();
  const { color, fetchColor, error } = useColor();
  const { products, fetchProducts } = useProducts();
  const { product, fetchProduct } = useProduct();
  const history = useHistory();
  const query = useQuery();
  const globalId = query.get('globalId');

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  useEffect(() => {
    fetchColor(cccid);
    window.scrollTo({ top: 0 });
  }, [cccid, fetchColor]);

  useEffect(() => {
    if (error?.response?.status === 400 || error?.response?.status === 404) {
      history.push('/notfound');
    }
  }, [error, history]);

  const colorProducts = useMemo(
    () => color && products?.filter((product) => color.products?.includes(product.globalId)),
    [products, color]
  );

  useEffect(() => {
    if (globalId) {
      fetchProduct({ globalId });
    }
  }, [globalId, fetchProduct]);

  useEffect(() => {
    color && updateMeta(metas.colorDetails, { colorName: color.defaultColorName });
  }, [color]);

  const colorHydrated = useMemo(() => color && hydrateColor(color), [color]);

  const textColor = useMemo(() => color && getColorNameByBgColorHex(color.rgb), [color]);

  if (colorHydrated) {
    return (
      <Page title="Our Colours" color={textColor} bgColor={`#${colorHydrated.rgb}`}>
        <ProductColor
          color={colorHydrated}
          colorProducts={colorProducts}
          product={product}
          mode="Color"
        />
        {colorHydrated.videoId && colorHydrated.coverId && (
          <ColorVideo coverId={colorHydrated.coverId} videoId={colorHydrated.videoId} />
        )}
        <ColorCombinations color={colorHydrated} />
        <Photos>
          <Image src={colorHydrated.inspirationalImages?.[3].url} />
          <Image src={colorHydrated.inspirationalImages?.[2].url} />
          <Image src={colorHydrated.inspirationalImages?.[1].url} />
          <Image src={colorHydrated.inspirationalImages?.[0].url} />
        </Photos>
        {config.sprinklrEnabledColor && <Sprinklr color={colorHydrated} />}
      </Page>
    );
  }

  return null;
};

export default ColorDetailsPage;
