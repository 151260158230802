import React, { useEffect, FC } from 'react';
import {
  useProducts,
  FlexCol,
  useCmsItem,
  useCmsImage,
  Image,
} from '@thisisdevelopment/akzonobel-core';
import { Page, ProductCard, Loading } from '@thisisdevelopment/heritage-core-library';
import { OurProductsType } from 'pages/OurProducts';
import TagManager from 'react-gtm-module';
import { getArgsProductImpressions } from 'util/datalayer';

const Products: FC = () => {
  const { products, fetchProducts, isLoading } = useProducts();
  const [page, fetchPage, isLoadingPage] = useCmsItem<OurProductsType>('our_products', {
    single: true,
  });
  const { image, fetchImage, isLoading: isLoadingBackground } = useCmsImage();

  useEffect(() => {
    fetchProducts();
    fetchPage();
  }, [fetchProducts, fetchPage]);

  useEffect(() => {
    if (products && products.length > 0) {
      TagManager.dataLayer(getArgsProductImpressions(products));
    }
  }, [products]);

  useEffect(() => {
    if (page?.product_list_background) {
      fetchImage(page?.product_list_background);
    }
  }, [page, fetchImage]);

  if (isLoading || isLoadingPage || isLoadingBackground) {
    return <Loading />;
  }

  if (page) {
    return (
      <Page title={page.title} headline={page.headline}>
        <FlexCol gap="40px" mb="60px">
          {products.map((product) => (
            <ProductCard key={product.globalId} product={product}>
              {image && <Image src={image.src} width="100%" height="100%" objectFit="cover" />}
            </ProductCard>
          ))}
        </FlexCol>
      </Page>
    );
  }

  return null;
};

export default Products;
