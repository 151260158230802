import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from 'routes/history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ErrorHandling } from '@thisisdevelopment/heritage-core-library';
import { init } from 'config';

init();

ReactDOM.render(
  <React.StrictMode>
    <ErrorHandling>
      <Router history={history}>
        <App />
      </Router>
    </ErrorHandling>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
