import React from 'react';
import { Page } from '@thisisdevelopment/heritage-core-library';

function NotFound() {
  return (
    <Page
      prefix="404"
      title="Page not found"
      headline="The link is broken or the page has been moved."
    />
  );
}

export default NotFound;
