import colorEnrichment from './colorEnrichment.json';
import { Color, InspirationalImage } from '@thisisdevelopment/akzonobel-core';

const getColorEnrichment = (cccid: number) => colorEnrichment.find((item) => item.cccid === cccid);

export const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const getInspirationalImages = (cccid: number): InspirationalImage[] => {
  const baseUrl = 'https://s3-eu-west-1.amazonaws.com/aats3-eieher-prod-1dcdb-public/inspirational';
  const places = shuffleArray(['hallway', 'living-room', 'dining-room', 'office', 'bedroom']);
  return places.map((place) => ({
    url: `${baseUrl}/${cccid}-${place}.jpg`,
    thumbnail: `${baseUrl}/thumb/${cccid}-${place}.jpg`,
  }));
};

interface HydratedColor extends Color {
  description?: string;
  videoId?: number;
  coverId?: number;
}

export const hydrateColor = (color: Color): HydratedColor => ({
  ...color,
  inspirationalImages: getInspirationalImages(color.colorCollectionColorId),
  ...getColorEnrichment(color.colorCollectionColorId),
});
