import styled, { css } from 'styled-components';
import { ButtonElement, FlexRow } from '@thisisdevelopment/akzonobel-core';
import { ContainerInner } from 'elements';
import theme from 'theme';

export const ColorsWrapper = styled(ContainerInner)`
  padding: 50px 0;
  display: flex;
  gap: 50px;

  @media ${theme.mediaQuery.tablet} {
    flex-direction: column;
    padding-top: 20px;
    gap: 20px;
  }
`;

export const ColorsHeader = styled.header`
  width: 400px;

  h1 {
    color: ${({ theme }) => theme.colors.brandGold};
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 600;
  }

  @media ${theme.mediaQuery.tablet} {
    width: unset;

    h1 {
      display: none;
    }

    p {
      margin: 0;
      text-align: center;
    }
  }
`;

export const ColorsContent = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 80px;
  gap: 10px;

  @media ${theme.mediaQuery.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
`;

export const GroupButton = styled(ButtonElement)<{ isActive: boolean }>`
  background-color: transparent;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.brandGold};
  padding: 0 10px;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
  height: 100%;

  &:not(:last-child) {
    border-right: 2px solid ${({ theme }) => theme.colors.brandGold};
  }

  &:hover {
    cursor: pointer;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.colors.black};
    `}
`;

export const GroupImageWrapper = styled.figure`
  position: relative;
  margin: 0;

  img {
    height: 400px;
    width: 100%;
    object-fit: cover;
  }

  @media ${theme.mediaQuery.tablet} {
    img {
      height: 150px;
    }
  }
`;

export const GroupName = styled.figcaption`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 4rem;
  font-weight: 600;
  text-transform: uppercase;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);

  @media ${theme.mediaQuery.tablet} {
    font-size: 2rem;
  }
`;

export const FlexRowStyled = styled(FlexRow)`
  @media ${theme.mediaQuery.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;
