import styled from 'styled-components';
import theme from 'theme';

export const ContactUsWrapper = styled.div`
  display: flex;
  gap: 60px;
  margin: 60px 0;

  @media ${theme.mediaQuery.tablet} {
    gap: 20px;
  }

  @media ${theme.mediaQuery.mobile} {
    flex-direction: column;
    margin: 30px 0;
  }
`;

export const SendAMessage = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.brandGold};
`;

export const MessageSent = styled(SendAMessage)`
  flex: 1;
`;

export const CallUs = styled.div`
  background-color: ${({ theme }) => theme.colors.brandBlue};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  padding: 40px;
  width: 430px;

  @media ${theme.mediaQuery.tablet} {
    width: 300px;
    padding: 20px;
  }

  @media ${theme.mediaQuery.mobile} {
    background-color: transparent;
    padding: 40px 0;
    color: ${({ theme }) => theme.colors.brandBlueDark};
  }

  a {
    color: ${({ theme }) => theme.colors.brandGold};
  }
`;
