import theme from 'theme';
import { CmsImage, ContainerInner } from '@thisisdevelopment/heritage-core-library';
import styled from 'styled-components';

export const ArticleContainer = styled(ContainerInner)`
  margin-top: 80px;
  margin-bottom: 80px;
`;

export const MainImage = styled(CmsImage)`
  width: 100%;
  height: 600px;
  object-fit: cover;
  margin-top: 70px;

  @media ${theme.mediaQuery.mobile} {
    margin-top: 40px;
    height: 250px;
  }
`;
